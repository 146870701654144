export const Routes = {
  Home: '/',
  Payment: '/payment',
  Products: {
    Root: {
      pathname: '/',
      query: { viewmode: 'listproducts' },
    },
  },
  Admin: {
    Root: '/admin',
    TagMasterList: '/admin/tag-master-list',
    Projects: {
      Root: '/admin/projects',
      Create: '/admin/projects/create',
      Project: {
        Root: '/admin/projects/[projectId]',
        Edit: '/admin/projects/[projectId]/edit',
        CreateInstance: '/admin/projects/[projectId]/create-instance',
        ProjectInstance: {
          Root: '/admin/projects/[projectId]/[projectInstanceId]',
          Edit: '/admin/projects/[projectId]/[projectInstanceId]/edit',
        },
      },
    },
  },

  Authority: {
    Root: '/authority',
    Manage: '/authority/manage',
    DiscloserList: '/authority/manage/[discloserListId]',
    Track: '/authority/track',
    AddAnOrganisation: '/authority/manage/[discloserListId]/add-an-organisation',
    TrackingPortfolios: {
      Root: '/authority/manage/[discloserListId]/tracking-portfolios',
      Create: '/authority/manage/[discloserListId]/tracking-portfolios/create',
      Edit: '/authority/manage/[discloserListId]/tracking-portfolios/[trackingPortfolioId]/edit',
      TrackingPortfolio: '/authority/manage/[discloserListId]/tracking-portfolios/[trackingPortfolioId]',
    },
    Samples: {
      Create: '/authority/manage/[discloserListId]/samples/create',
      Root: '/authority/manage/[discloserListId]/samples',
      IndividualSample: {
        Root: '/authority/manage/[discloserListId]/samples/[trackingPortfolioId]',
        Add: '/authority/manage/[discloserListId]/samples/[trackingPortfolioId]/add',
        Edit: '/authority/manage/[discloserListId]/samples/[trackingPortfolioId]/edit',
      },
    },
    Projects: {
      Track: '/authority/manage/[discloserListId]/projects/track',
      Root: '/authority/manage/[discloserListId]/projects',
      IndividualProject: {
        Root: '/authority/manage/[discloserListId]/projects/[projectInstanceId]',
        Add: '/authority/manage/[discloserListId]/projects/[projectInstanceId]/add',
      },
    },
  },

  Discloser: {
    Root: '/discloser',
    Disclose: '/discloser/disclose',
    History: '/discloser/history',
    Manage: '/discloser/manage',
  },

  Account: '/account',

  Organisation: {
    Root: '/organisation',
    Details: '/organisation/details',
    DisclosureApi: '/organisation/disclosure-api',
    Users: '/organisation/users',
  },
  Setup: {
    Root: '/setup',
    Overview: '/setup/overview',
    Corporate: {
      Section1: {
        Revenue: '/setup/corporate/general-information/revenue',
        Fte: '/setup/corporate/general-information/fte',
        Version: '/setup/corporate/general-information/version',
      },
      Section2: '/setup/corporate/acs',
      Section3: '/setup/corporate/env-issues-assessment-identification',
      Section4: {
        DiscloseClimateChange: '/setup/corporate/env-issues-assignment/disclose-climate-change',
        DiscloseForestsAndWater: '/setup/corporate/env-issues-assignment/disclose-forests-and-water',
        IntentToSubmit: '/setup/corporate/env-issues-assignment/intent-to-submit',
      },
      Section5: '/setup/corporate/commodities-disclosure',
      Section6: '/setup/corporate/mines',
    },
    City: {
      Section1: '/setup/city/section-1',
      Section2: '/setup/city/section-2',
    },
    Submit: '/setup/submit',
  },

  Help: '/help',

  Knowledge: '/knowledge-centre',
  SupportResources: {
    root: '/support-resources',
    articles: '/support-resources/articles',
    events: '/support-resources/events',
    resources: '/support-resources/resources',
  },

  Questionnaire: {
    Root: '/questionnaire',
    Response: '/questionnaire/response',
    Submit: '/questionnaire/submit',
    ScenarioTesting: '/questionnaire/preview',
  },

  Guidance: {
    root: '/guidance',
    questionnaire: '/guidance/questionnaire',
  },

  DataAndInsights: '/data-and-insights',

  MyRequests: '/my-requests',

  Registration: '/registration-page',
};
