// 3rd
import { fetch } from 'cross-fetch';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  InMemoryCacheConfig,
  NextLink,
  Operation,
  makeVar,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// config
import { discloserConfig, responseConfig } from '@config';
import * as Sentry from '@sentry/nextjs';

export const tokenVar = makeVar<string | null>(null);

// Dynamically update the token value and have it automatically applied to subsequent requests
export const updateToken = (newToken: string) => {
  tokenVar(newToken);
};

const authLink = setContext((_, { headers }) => {
  //  Get the token from the reactive variable
  const token = tokenVar();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const loggingLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const query = operation.query.loc?.source?.body;
  const variables = operation.variables;

  // Forward the operation to the next link in the chain
  return forward(operation).map(response => {
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'graphql',
      message: 'Request/Response',
      data: {
        query: query,
        variables: variables,
        response: response.data,
      },
    });

    return response;
  });
});

const withAuthClient = (httpLink: HttpLink, config?: InMemoryCacheConfig) => {
  return new ApolloClient({
    link: ApolloLink.from([
      authLink,
      loggingLink,
      httpLink,
    ]),
    cache: new InMemoryCache(config),
    connectToDevTools: true,
  });
};

export const responseClient = withAuthClient(
  new HttpLink({
    fetch: (...args) => fetch(...args),
    uri: responseConfig.graphql,
  }),
);

export const disclosureClient = withAuthClient(
  new HttpLink({
    fetch: (...args) => fetch(...args),
    uri: discloserConfig.graphql,
  }),
);
