// next
// routes
import Image from 'next/image';
import Link from 'next/link';
import { Routes } from '@/utils/urls';
// mui
import { Box } from '@mui/material';

type Props = {
  isOpened: boolean;
  height?: number;
  width?: number;
};

export default function Logo({ isOpened, height = 32, width = 75 }: Props) {
  return (
    <Box
      component="div"
      justifyContent={isOpened ? 'initial' : 'center'}
      display="inline-flex"
      width="100%"
    >
      <Link href={Routes.Home}>
        {isOpened ? (
          <Image
            src="/images/logo.svg"
            alt="CDP Logo"
            width={width}
            height={height}
            priority
          />
        ) : (
          <Image
            src="/images/logoSm.svg"
            alt="CDP Logo"
            width={36}
            height={33}
            priority
          />
        )}
      </Link>
    </Box>
  );
}
